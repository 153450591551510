html {
  --global-bg-color: #d2e9fe;
}

body {
  font-family: sans-serif;
}

button {
  cursor: pointer;
  text-align: left;
  color: inherit;
  border: none;
  margin: 0;
  padding: 0;
}

header {
  height: var(--header-height);
  border-bottom: 1px solid var(--color-gray-700);
  color: var(--color-gray-300);
  display: flex;
}

header .side {
  width: var(--header-height);
  place-content: center;
  display: grid;
}

h1 {
  font-size: 2rem;
  line-height: var(--header-height);
  text-align: center;
  flex: 1;
}

@media (max-width: 25rem) {
  h1 {
    font-size: 1.25rem;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

/*# sourceMappingURL=index.7b23e15b.css.map */
