/*
  Custom styles // JS
*/
html {
  --global-bg-color: hsl(209, 96%, 91%);
}

body {
  font-family: sans-serif;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  /* background: transparent; */
  cursor: pointer;
  text-align: left;
  color: inherit;
}

header {
  display: flex;
  height: var(--header-height);
  border-bottom: 1px solid var(--color-gray-700);
  color: var(--color-gray-300);
}

header .side {
  width: var(--header-height);
  display: grid;
  place-content: center;
}

h1 {
  flex: 1;
  font-size: 2rem;
  line-height: var(--header-height);
  text-align: center;
}

@media (max-width: 25rem) {
  h1 {
    font-size: 1.25rem;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
